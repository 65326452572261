import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, Textarea } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../../data/snippets/textarea.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Textarea: Textarea }}
        code={snippet}
        platform="react"
        gitHubLink="forms/textarea"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="label" types={['string']}>
            <Text>The text that appears above the textarea.</Text>
          </PropListItem>

          <PropListItem name="name" types={['string']}>
            <Text>
              A name to bind the id of the textarea to the for of the label.
            </Text>
          </PropListItem>

          <PropListItem name="required" types={['bool']}>
            <Text>
              Determines whether the required text appears next to the label.
            </Text>
          </PropListItem>

          <PropListItem name="disabled" types={['bool']}>
            <Text>Determines whether the textarea is disabled.</Text>
          </PropListItem>

          <PropListItem name="hasError" types={['bool']}>
            <Text>
              Determine whether to show an error state (red text and border) on
              the input.
            </Text>
          </PropListItem>

          <PropListItem name="helpText" types={['string', 'element']}>
            <Text>
              The text that appears below the textarea, usually to guide the
              user on what to provide.
            </Text>
          </PropListItem>

          <PropListItem name="hideLabel" types={['bool']}>
            <Text>Determines whether to hide the label.</Text>
          </PropListItem>

          <PropListItem name="hasDivider" types={['bool']}>
            <Text>
              Determines whether to show a visual divider above the textarea.
            </Text>
          </PropListItem>

          <PropListItem name="minHeight" types={['string']}>
            <Text>Changes the min-height from the default of 200px.</Text>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>Add a custom class to the form element.</Text>
          </PropListItem>

          <PropListItem name="textareaRef" types={['func']}>
            <Text>A ref passed to the native textarea element.</Text>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique-enough string that appears as a data attribute in the
              rendered code and serves as a hook for automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea">a regular HTML textarea</Link>.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            If you pass in a <code>value</code> prop, be sure you also pass in
            an <code>onChange</code> prop so the{' '}
            <Link href="https://reactjs.org/docs/forms.html#controlled-components">
              component will update
            </Link>{' '}
            when the user types.
          </li>
          <li>
            Native textarea props can be passed in such as <code>value</code>,{' '}
            <code>rows</code>, <code>placeholder</code>, etc.
          </li>
          <li>
            Since the <code>name</code> attribute is passed to the{' '}
            <code>textarea</code>
            ’s <code>id</code>, make sure you provide a unique name.
          </li>
          <li>
            To show an error message, simply pass in <code>hasError</code> and
            put the error text in the <code>helpText</code> attribute.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
