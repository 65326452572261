import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import { Textarea } from 'hudl-rn-uniform-ui';

const snippet = require('raw-loader!../../../../../data/snippets/rn-textarea.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Textarea: Textarea }}
        code={snippet}
        platform="react-native"
        gitHubLink="forms/textarea"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="label" types={['string']}>
            <Text>The text that appears above the textarea.</Text>
          </PropListItem>

          <PropListItem name="required" types={['bool']}>
            <Text>
              Determines whether the required text appears next to the label.
            </Text>
          </PropListItem>

          <PropListItem name="disabled" types={['bool']}>
            <Text>Determines whether the textarea is disabled.</Text>
          </PropListItem>

          <PropListItem name="hasError" types={['bool']}>
            <Text>
              Determine whether to show an error state (red text and border) on
              the textarea.
            </Text>
          </PropListItem>

          <PropListItem name="helpText" types={['string', 'element']}>
            <Text>
              The text that appears below the textarea, usually to guide the user
              on what to provide.
            </Text>
          </PropListItem>

          <PropListItem name="minHeight" types={['number']}>
            <Text>
              Changes the default min-height for the textarea.
            </Text>
          </PropListItem>

          <PropListItem name="space" types={['oneOf', 'string']}>
            <Text>
              Any of the{' '}
              <Link href="/resources/code-variables/space?reactnative">
                space variables
              </Link>
              .
            </Text>
          </PropListItem>

          <PropListItem name="testID" types={['string']}>
            <Text>
              A unique string to control this text area in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="accessibilityLabel" types={['string']}>
            <Text>
              Overrides the text that's read by the screen reader when the user interacts with the text area.
            </Text>
          </PropListItem>

          <PropListItem name="theme" types={['oneOf', 'string']}>
            <Text>Determines the theme of the textarea.</Text>
            <List type="unordered">
              <li>
                <code>light</code> (default)
              </li>
              <li>
                <code>dark</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other input props from{' '}
              <Link href="https://facebook.github.io/react-native/docs/textinput.html">React Native's TextInput</Link>.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            To show an error message, simply pass in <code>hasError</code> and
            put the error text in the <code>helpText</code> attribute.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
